<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    bottom
    left
    max-width="90%"
    min-width="275"
    offset-x
    offset-y
    origin="top right"
    transition="scale-transition"
  >
    <template v-slot:activator="{ attrs, on }">
      <v-card
        id="settings"
        class="py-2 px-4"
        color="#0000004D"
        dark
        flat
        style="position: fixed; top: 164px; right: -35px;"
        width="100"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon large dark>mdi-phone</v-icon>
      </v-card>
    </template>

    <v-card class="mx-auto my-12" max-width="374">
      <template slot="progress">
        <v-progress-linear color="deep-purple" height="10" indeterminate></v-progress-linear>
      </template>

      <v-img height="250" :src="require(`@/assets/contact.jpg`)"></v-img>

      <v-card-title>Contact Us</v-card-title>

      <v-card-text>
        <v-chip-group v-model="selection" active-class="deep-purple deep-purple white--text" column>
          <v-chip>+91 6364887210 / 11 / 12</v-chip>
        </v-chip-group>
      </v-card-text>

      <v-divider class="mx-4"></v-divider>

      <v-card-title>Email Us</v-card-title>

      <v-card-text>
        <v-chip-group v-model="selection" active-class="deep-purple deep-purple white--text" column>
          <v-chip>sales@fleetop.net</v-chip>
        </v-chip-group>
      </v-card-text>
    </v-card>
  </v-menu>
</template>




<script>
export default {
  name: "HomeSettings",

  data() {
    return {
      colors: [this.$vuetify.theme.currentTheme.primary, "#9368e9", "#F4511E"],
      menu: false
    };
  },

  computed: {
    currentThemePrimary: {
      get() {
        return this.$vuetify.theme.currentTheme.primary;
      },
      set(val) {
        const target = this.$vuetify.theme.isDark ? "dark" : "light";

        this.$vuetify.theme.themes[target].primary = val;
      }
    }
  }
};
</script>
